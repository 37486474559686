<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="4">
          <a-form-item label="产品名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入产品名称"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="3">
          <a-form-item label="状态">
            <a-select
              v-decorator="['effective']"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option key="true" value="true">
                生效
              </a-select-option>
              <a-select-option key="false" value="false">
                失效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="4">
          <a-form-item label="销售范围">
            <a-select
              v-decorator="['sales_scope']"
              placeholder="销售范围"
              allow-clear
            >
              <a-select-option key="1" value="order">
                订购产品
              </a-select-option>
              <a-select-option key="2" value="other_items">
                其他项目
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="4">
          <a-form-item label="惠民赠送">
            <a-select
              v-decorator="['benefit_the_people']"
              placeholder="是否惠民赠送"
              allow-clear
            >
              <a-select-option key="true" value="true">
                是
              </a-select-option>
              <a-select-option key="false" value="false">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="4">
          <a-form-item label="产品类型">
            <a-select
              v-decorator="['product_type']"
              placeholder="产品类型"
              allow-clear
              :loading="typeOptions.length === 0"
            >
              <a-select-option
                v-for="type in typeOptions"
                :key="type.slug"
                :value="type.slug"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="4">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { getProductType } from '@/api/product_type'

export default {
  name: 'SearchTenantProduct',
  data() {
    return {
      typeOptions: [],
      form: this.$form.createForm(this, { name: 'tenant_product_search' })
    }
  },
  created() {
    this.getTypeOptions()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    }
  }
}
</script>

