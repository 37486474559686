<template>
  <div>
    <search-tenant-product @submit="submitSearch" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="name" slot-scope="name,record">
        <router-link :to="{name:'tenant_products_info',params:{id: record.id}}">{{ name }}</router-link>
      </template>
      <template slot="images" slot-scope="imgs, record">
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective, record">
        <span
          v-if="record.info_modify_apply_user_id === $store.getters.userId &&
            (record.info_modify_apply_status === 'uncheck' || record.info_modify_apply_status === 'checking')"
        >申请处理中</span>
        <a-switch
          v-else
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="showApplyModal(record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
          <a @click="showDeleteModal(record)" style="color: red">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增套餐模态框 -->
    <new-tenant-product
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑套餐模态框 -->
    <edit-tenant-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <!-- 编辑套餐套餐有效性模态框 -->
    <effective-tenant-Product
      v-if="isShowApplyModal"
      :visible.sync="isShowApplyModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 删除套餐套餐有效性模态框 -->
    <delete-tenant-product
      v-if="isShowDeleteModal"
      :visible.sync="isShowDeleteModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="Product"
      :visible.sync="isShowOperationHistoryModal"
    />

    <sort-product
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      :columns="sortColumns"
      table-name="products"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchTenantProduct from '@/views/products/tenant/Search'
import PreviewImage from '@/components/PreviewImage'
import { deleteTenantProduct, findTenantProducts, findTenantSortProducts } from '@/api/product'

export default {
  name: 'TenantProductList',
  components: {
    Pagination,
    PreviewImage,
    SearchTenantProduct,
    SortProduct: () => import('@/components/Sort/product'),
    NewTenantProduct: () => import('@/views/products/tenant/New'),
    EditTenantProduct: () => import('@/views/products/tenant/Edit'),
    EffectiveTenantProduct: () => import('@/views/products/tenant/Effective'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    DeleteTenantProduct: () => import('@/views/products/tenant/Delete')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowApplyModal: false, // 是否显示申请修改有效性表单弹窗
      isShowDeleteModal: false, // 是否显示删除
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      previewVisible: false,
      isApplying: false,
      isShowSortModal: false, // 是否显示排序弹窗
      sortData: [], // 排序弹窗页面数据
      previewImages: [],
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 120,
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '型号',
          width: 120,
          ellipsis: true,
          dataIndex: 'product_model'
        },
        {
          title: '项目代码',
          width: 100,
          ellipsis: true,
          dataIndex: 'code'
        },
        {
          title: '拼音码',
          width: 100,
          ellipsis: true,
          dataIndex: 'pinyin'
        },
        {
          title: '状态',
          width: 120,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '类型',
          width: 90,
          ellipsis: true,
          dataIndex: 'product_type'
        },
        {
          title: '是否惠民赠送',
          width: 120,
          dataIndex: 'str_benefit_the_people'
        },
        {
          title: '销售范围',
          width: 160,
          ellipsis: true,
          dataIndex: 'str_sales_scope'
        },
        {
          title: '产生任务',
          width: 120,
          ellipsis: true,
          dataIndex: 'str_tasks'
        },
        {
          title: '销售渠道',
          width: 160,
          ellipsis: true,
          dataIndex: 'str_sales_channel'
        },
        {
          title: '规格数量',
          width: 120,
          ellipsis: true,
          dataIndex: 'item_count'
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          ellipsis: true,
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '产品介绍',
          width: 150,
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 180,
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    sortColumns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'name',
          width: 120,
        },
        {
          title: '产品类型',
          dataIndex: 'product_type_text',
          width: 120,
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showDeleteModal(record) {
      this.editingRecord = record
      this.isShowDeleteModal = true
    },

    showSortModal() {
      findTenantSortProducts().then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    showApplyModal(record) {
      this.editingRecord = record
      this.isShowApplyModal = true
    },

    showMaintainModal(record) {
      this.editingRecord = record
      this.isShowMaintainModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      // 重置排序
      this.sort = this.$options.data().sort
      this.loading = true
      findTenantProducts().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findTenantProducts(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
