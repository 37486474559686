import request from '@/utils/request'

// 查询产品类型
export function getProductType() {
  return request({
    url: `/products/product_type/options`,
    method: 'get'
  })
}
// 查询产品销售渠道
export function getProductSaleChannel() {
  return request({
    url: `/products/sale_channel/options`,
    method: 'get'
  })
}
